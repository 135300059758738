import React from "react"
import { graphql } from "gatsby"

import { useBreakpoint } from "gatsby-plugin-breakpoints"
import { useTranslation } from 'gatsby-plugin-react-i18next'

import SmoothScroll from "../components/layout/smoothScroll";

import Layout from '../components/layout/layout'
import Seo from '../components/seo'
import MenuGlobal from '../components/menu'
import InnerComponent from '../components/innerComponent'


import '../sass/app.scss';

const CookiesPage = ({ location }) => {
  const breakpoints = useBreakpoint();

  const { t } = useTranslation()
  const dataGlobal = t("global", { returnObjects: true })

  const text = {
    "title": null,
    "top": {
      "slogan": {
        "blue": ["0"],
        "first": [dataGlobal.cookies.title],
        "second": []
      },
      "sloganmobile": {
        "blue": ["0"],
        "first": [dataGlobal.cookies.title
        ],
        "second": [
        ],
        "third": [],
        "fourth": []
      }
    }
  }

  return (
    <Layout mobile={useBreakpoint().mobile} post={null} data={dataGlobal} page={dataGlobal.cookies.page} seo={dataGlobal.cookies.seo} >
      {breakpoints.mobile ? <MenuGlobal video src="http://news.invisual.pt/wp/wp-content/uploads/2024/10/bg_cookies_1_xnwhnv-1.mp4"
        global={dataGlobal} mobile location={location} textstatic={text} dark />
        : <MenuGlobal video src="http://news.invisual.pt/wp/wp-content/uploads/2024/10/bg_cookies_1_xnwhnv-1.mp4"
          global={dataGlobal} location={location} textstatic={text} dark />
      }
      <SmoothScroll>
          <InnerComponent data={dataGlobal.cookies} />
        
        <div className="container-scroll-heigth" />

      </SmoothScroll>
    </Layout>
  )
}

export default CookiesPage


export const pageQuery = graphql`
  query($language: String!) {
    locales: allLocale(filter: {ns: {in: ["global", "homepage"]}, language: {eq: $language}}) {
        edges {
          node {
            ns
            data
            language
          }
        }
    }
  }
`